import React, { useState, useCallback, useEffect, useRef } from 'react';

import Nuage from '@oxeva/nuage-sdk';

import { useForm } from 'react-hook-form';
import { ComplexButton, ErrorMessage } from '@nuage/ui-components';

import FormLanding from '@molecules/group/formLanding';
import DetailsCoupon from '@molecules/group/detailsCoupon';
import MainLayout from '@organisms/main/mainLayout';
import Footer from '@organisms/main/footer';

import ComplexField from '@molecules/interactive/complexField';

import { dispatchGtagEvent } from '@utils/eventsManager';
import lang from '@utils/lang';
import NuageClient from '@utils/client';

import './vip.scss';

const Vip = () => {
    const { _string } = lang;

    const [clObj, setClObj] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [errorType, setErrorType] = useState(null);

    const [validMail, setValidMail] = useState(null);

    const timer = useRef(null);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        clearErrors,
        trigger,
    } = useForm({
        mode: 'onSubmit',
    });

    // eslint-disable-next-line no-control-regex
    const formatCoupon = (coupon) => coupon.toUpperCase().replace(/[\u0000-\u001F\u007F-\u009F]/g, '');

    const onChangeCheck = useCallback(() => {
        if (!buttonClicked) { return; }
        clearErrors();
        trigger();
    }, [clearErrors, trigger, buttonClicked]);

    const onSignin = useCallback(({ mail, coupon }) => {
        if (!clObj) { return; }
        clearTimeout(timer.current);
        setLoading(true);

        const formatedCoupon = formatCoupon(coupon);

        clObj.cl.coupon({ name: formatedCoupon }).onReady((error, data) => {
            let displayError = false;
            // Be more specific with 404 ?
            if (error || !data) {
                displayError = _string('vip_error_coupon_invalid');
            } else {
                if (data.isUsed) {
                    displayError = _string('vip_error_coupon_depleted');
                }

                if (data.validUntil) {
                    const validDate = new Date(data.validUntil);
                    const now = new Date();
                    if (validDate < now) {
                        displayError = _string('vip_error_coupon_expired');
                    }
                }
            }

            if (!displayError) {
                const invitation = new Nuage.Invitation({
                    email: mail,
                    coupon: formatedCoupon,
                });
                clObj.cl.persist(invitation).onReady((errorInvitation) => {
                    if (errorInvitation) {
                        setLoading(false);
                        switch (errorInvitation.status) {
                        case 429: {
                            setErrorType('requestError');
                            break;
                        }
                        case 422: {
                            setErrorType('forbidden');
                            break;
                        }
                        default: {
                            setErrorType('alert');
                            break;
                        }
                        }
                        timer.current = setTimeout(() => {
                            setErrorType(null);
                        }, 3000);
                    } else {
                        // window.location.href = '/';
                        setValidMail(mail);
                        setSuccess(true);
                        dispatchGtagEvent('submit', {
                            event_category: 'form',
                            event_label: 'compte',
                        });
                    }
                });
            } else {
                setError('coupon', {
                    type: 'manual',
                    message: displayError,
                });
                setLoading(false);
            }
        });
    }, [_string, setError, clObj]);

    useEffect(() => {
        const newClObj = new NuageClient();
        setClObj(newClObj);
    }, []);

    return (
        <MainLayout
            title={_string('vip_titleTab')}
            socials={_string('vip_socials')}
            classTitle="vip"
            footer={false}
        >
            <FormLanding
                title={_string('vip_title')}
                titleForm={_string('vip_form_title')}
                confirmTitle={_string('vip_confirm_title')}
                confirmText={_string('vip_confirm_text', { mail: validMail })}
                infoForm={_string('vip_form_info')}
                loading={false}
                success={success}
                onSubmit={handleSubmit(onSignin)}
            >
                <div className="vip__field">
                    <ComplexField
                        name="mail"
                        id="mail"
                        rules={{
                            required: _string('vip_error_mail_invalid'),
                            pattern: {
                                // Official regex, do not need eslint
                                // eslint-disable-next-line no-control-regex
                                value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                                message: _string('vip_error_mail_invalid'),
                            },
                        }}
                        controlForm={control}
                        placeholder={_string('vip_form_mail_placeholder')}
                        noValidStateField
                        disabled={loading}
                    />
                    <ComplexField
                        name="coupon"
                        id="coupon"
                        rules={{
                            required: _string('vip_error_coupon_required'),
                        }}
                        controlForm={control}
                        placeholder={_string('vip_form_coupon_placeholder')}
                        onFieldChange={onChangeCheck}
                        noValidStateField
                        formatValue={formatCoupon}
                        disabled={loading}
                    />
                </div>
                { errorType && (
                    <ErrorMessage opened>
                        {_string(`intro_${errorType}`)}
                    </ErrorMessage>
                )}
                <ComplexButton
                    onClick={() => {
                        setButtonClicked(true);
                        handleSubmit(onSignin);
                    }}
                    disabled={Object.keys(errors).length !== 0 || errorType || loading}
                    type="submit"
                    color="secondary"
                    loading={loading}
                >
                    {_string('vip_form_btn')}
                </ComplexButton>
            </FormLanding>
            <div className="vip__info">
                <DetailsCoupon />
            </div>
            <Footer />
        </MainLayout>
    );
};

export default Vip;
